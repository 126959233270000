/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

//import $ from 'jquery';
const $ = require('jquery');

global.$ = $;
global.jQuery = $;
Window.prototype.$ = $;

// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require('bootstrap');
require('jquery-ui-bundle');

require("jquery-ui/ui/widgets/autocomplete");
require("jquery-ui/ui/widgets/draggable");
require("jquery-ui/ui/widgets/selectmenu");
require("jquery-ui/ui/widgets/slider");
require('jquery-ui/themes/base/all.css');

// any CSS you import will output into a single css file (app.css in this case)
import './styles/global.scss';

// start the Stimulus application
import 'bootstrap';
